<template>
    <div class="winners">
        <!-- currencies.length нужен для идентификации загрузки валют, т.к. в них находится
             subunits_to_unit который уже используется для подсчета выигрыша  -->
        <div v-if="winnersList.length > 0 && currencies.length" class="winners__wrapper">
            <div class="winners__title">
                {{ $t("WINNERS.TITLE") }}
            </div>
            <TransitionGroup class="winners__list" name="winners" tag="ul">
                <a
                    v-for="item in winnersList"
                    :key="item.key"
                    :class="{ 'disabled': isGuest }"
                    class="winners__item"
                    @click.prevent="openGame(item)"
                >
                    <span
                        class="winners__item-thumb"
                        :style="{ 'background-image': 'url(' + skeleton + ')' }"
                    >
                        <FeImage
                            v-if="isGuest"
                            :src="iconLock"
                            class="winners__item-thumb-icon-lock"
                        />
                        <FePicture
                            v-if="item.game.image"
                            :alt="item.game.title"
                            :src="item.game.image"
                            :type="TypeImage.GAME_WINNER_THUMB"
                            class="winners__item-thumb-img"
                        />
                    </span>

                    <div class="winners__item-info">
                        <div class="winners__item-name">{{ usernamePrepare(item.username) }}</div>
                        <div class="winners__item-amount">{{ item.sum }}</div>
                        <div class="winners__item-game">{{ item.game.title }}</div>
                    </div>
                </a>
            </TransitionGroup>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUserBalance } from "@store/user/userBalance";
import { useUserInfo } from "@store/user/userInfo";
import { useWinners } from "@store/winners.ts";
import { routeNames } from "@router/routeNames";
import { currencyView } from "@helpers/currencyHelper";

import FeImage from "@ui/FeImage/FeImage.vue";
import FePicture from "@ui/FePicture/FePicture.vue";

import iconLock from "@theme/icons/icon-lock.svg";
import defaultImg from "@theme/images/BrandImages/logo-sceleton.png";

import { getGameImagePath } from "@helpers/gameImage";
import { nicknameReplace } from "@helpers/textFilters.js";
import { TypeImage } from "@theme/configs/imgResize";
import { hasGameDemo, paramsUrlGamePage } from "@helpers/gameHelpers";
import { useGameCurrent } from "@store/games/gameCurrent";
import { useGamesCategory } from "@store/games/gamesCategory";
import { useEnvironments } from "@store/environments";
import { useCommon } from "@store/common";
import { useRootStore } from "@store/root";


const configsMinWins = {
    AUD: 15,
    CAD: 15,
    NZD: 15,
    EUR: 10,
    USD: 10,
    PLN: 50,
    JPY: 1200,
    NOK: 100,
    BRL: 60,
    BTC: 0.00025,
    ETH: 0.004,
    BCH: 0.02,
    LTC: 0.07,
    DOG: 50,
    USDT: 10,
};

function getRandomId() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return `_${Math.random().toString(36).substr(2, 9)}`;
}

/* eslint-disable camelcase */
export default {
    name: "Winners",
    components: {
        FeImage,
        FePicture,
    },
    props: {
        itemsPerView: {
            type: Number,
            default: 18,
        },
        offset: {
            type: Number,
            default: 1,
        },
        delay: {
            type: Number,
            default: 3000,
        },
    },
    data() {
        return {
            TypeImage,
            iconLock,
            skeleton: defaultImg,
            winners: [],
            activeItemIndex: 0,
            timer: null,
        };
    },
    computed: {
        ...mapState(useRootStore, {
            isGuest: "isGuest",
        }),
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),

        ...mapState(useGamesCategory, {
            getCollectionGames: "getCollection",
        }),
        ...mapState(useUserBalance, {
            currencies: "getCurrencies",
        }),

        ...mapState(useUserInfo, {
            userInfo: "getUserInfo",
            isLogged: "getIsLogged",
        }),

        winnersList() {
            return this.winners.slice(0, this.itemsPerView).map((winner) => {
                const { user_id: userId } = winner;
                const isMe = userId === this.userInfo && this.userInfo?.user_id;
                const username = isMe ? this.$t("WINNERS.YOU") : winner.username;

                const sum = typeof winner.sum !== "string"
                    ? currencyView(winner.sum, winner.currency, null, this.subUnits(winner.currency))
                    : winner.sum;

                return {
                    ...winner,
                    username,
                    sum,
                };
            });
        },
    },

    serverPrefetch() {
        return this.loadWinners().then((winners) => {
            this.addWinners(winners);
        });
    },

    mounted() {
        if (this.environment.useMocker) {
            return;
        }

        this.$bus.$on("websocket.public:wins", this.updateWinners);
        if (this.winners.length > 0 && this.delay) {
            this.slideWinners();
        }
    },
    beforeUnmount() {
        this.$bus.$off("websocket.public:wins", this.updateWinners);
        clearInterval(this.timer);
    },

    methods: {
        ...mapActions(useCommon, {
            subUnits: "getSubunitsToUnitsByCode",
        }),
        ...mapActions(useWinners, {
            loadWinners: "loadWinners",
        }),
        ...mapActions(useGameCurrent, {
            loadGameBySlug: "loadGameBySlug",
        }),

        async openGame(item) {
            try {
                const game = await this.loadGameBySlug(item.id);
                if (!game) {
                    return;
                }
                if (this.isLogged || hasGameDemo(game)) {
                    return this.$router.push({
                        name: routeNames.gameItem,
                        params: paramsUrlGamePage(game),
                    });
                }

                return this.$router.push({ name: routeNames.registration });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },

        slideWinners() {
            if (this.timer) {
                return;
            }

            this.timer = setInterval(() => {
                this.infiniteCarousel();
            }, this.delay);
        },

        gameLink(game) {
            // eslint-disable-next-line camelcase
            if (this.isLogged || game?.has_demo_mode) {
                return { name: routeNames.gameItem, params: { name: game.id } };
            }
            return { name: routeNames.registration };
        },

        addWinners(winners) {
            const newWinners = winners.map((winner) => {
                return {
                    ...winner,
                    key: getRandomId(),
                };
            });
            this.winners = [ ...newWinners, ...this.winners ].slice(0, this.itemsPerView);
        },
        unshiftWinners(newFirstWinner) {
            const newLength = this.winners.unshift(newFirstWinner);
            this.winners = this.winners.slice(0, newLength - 1);
        },
        infiniteCarousel() {
            const countWinners = this.winners.length - 1;

            const newFirstWinner = {
                ...this.winners[countWinners],
                key: getRandomId(),
            };

            this.unshiftWinners(newFirstWinner);
        },
        updateWinners({ data }) {
            const currentWin = data.round.win / this.subUnits(data.round.currency);
            if (currentWin >= configsMinWins[data.round.currency]) {
                return this.addWinners([ this.creteWinners(data) ]);
            }
        },

        creteWinners(winner) {
            const [ , slug ] = winner.game.identifier.split("/");
            return {
                game: {
                    has_demo_mode: false,
                    image: getGameImagePath(winner.game.identifier),
                    link: `play/${slug}`,
                    slug: slug,
                    title: winner.game.title,
                },
                id: winner.game.identifier,
                sum: currencyView(winner.round.win, winner.round.currency, null, this.subUnits(winner.round.currency)),
                currency: winner.round.currency,
                user_id: winner.player.nickname,
                username: winner.player.nickname,
                key: winner.player.nickname + Date.now(),
            };
        },

        usernamePrepare(username) {
            return nicknameReplace(username);
        },

    },

};
</script>

<style lang="scss" src="./style.scss"></style>
<style lang="scss" scoped>
.winners {
    &__item {
        &-thumb {
            background-size: 2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-color: var(--color-tertiary-4);

            &-img img {
                opacity: 0;
            }
        }
    }
}
</style>
